@tailwind components;
@tailwind utilities;

#printy6-blog {
  --color-primary-main: 4 120 87;
  --color-primary-dark: 17 94 89;
}

.prose .anchor {
  @apply absolute invisible no-underline;

  margin-left: -1em;
  padding-right: 0.5em;
  width: 80%;
  max-width: 700px;
  cursor: pointer;
}

.anchor:hover {
  @apply visible;
}

.prose a {
  @apply transition-all;
}

.prose .anchor:after {
  @apply text-gray-300 dark:text-gray-700;
  content: '#';
}

.prose *:hover > .anchor {
  @apply visible;
}

.prose pre {
  @apply border border-gray-200 bg-gray-50 dark:border-gray-700 dark:bg-gray-900;
}

.prose code {
  @apply text-gray-800 dark:text-gray-200 px-1 py-0.5 border border-gray-100 dark:border-gray-800 rounded-lg bg-gray-100 dark:bg-gray-900;
}

.prose pre code {
  @apply text-gray-800 dark:text-gray-200 p-0;
  border: initial;
}

.prose img {
  /* Don't apply styles to next/image */
  @apply m-0;
}

.prose figcaption {
  /* Don't apply styles to next/image */
  @apply text-center text-sm mt-2 text-gray-500;
}

.prose figcaption b {
  /* Don't apply styles to next/image */
  @apply font-semibold;
}

.prose figcaption a {
  /* Don't apply styles to next/image */
  @apply cursor-pointer no-underline hover:underline;
}
