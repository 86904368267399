.container {
  width: 100%;
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}
.prose {
  color: #333333;
  max-width: 65ch;
}
.prose :where([class~="lead"]):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-lead);
  font-size: 1.25em;
  line-height: 1.6;
  margin-top: 1.2em;
  margin-bottom: 1.2em;
}
.prose :where(a):not(:where([class~="not-prose"] *)) {
  color: #3D9970;
  text-decoration: underline;
  font-weight: 500;
}
.prose :where(a):not(:where([class~="not-prose"] *)):hover {
  color: #327E5C;
}
.prose :where(a):not(:where([class~="not-prose"] *)) code {
  color: #60a5fa;
}
.prose :where(strong):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-bold);
  font-weight: 600;
}
.prose :where(ol):not(:where([class~="not-prose"] *)) {
  list-style-type: decimal;
  padding-left: 1.625em;
}
.prose :where(ol[type="A"]):not(:where([class~="not-prose"] *)) {
  list-style-type: upper-alpha;
}
.prose :where(ol[type="a"]):not(:where([class~="not-prose"] *)) {
  list-style-type: lower-alpha;
}
.prose :where(ol[type="A" s]):not(:where([class~="not-prose"] *)) {
  list-style-type: upper-alpha;
}
.prose :where(ol[type="a" s]):not(:where([class~="not-prose"] *)) {
  list-style-type: lower-alpha;
}
.prose :where(ol[type="I"]):not(:where([class~="not-prose"] *)) {
  list-style-type: upper-roman;
}
.prose :where(ol[type="i"]):not(:where([class~="not-prose"] *)) {
  list-style-type: lower-roman;
}
.prose :where(ol[type="I" s]):not(:where([class~="not-prose"] *)) {
  list-style-type: upper-roman;
}
.prose :where(ol[type="i" s]):not(:where([class~="not-prose"] *)) {
  list-style-type: lower-roman;
}
.prose :where(ol[type="1"]):not(:where([class~="not-prose"] *)) {
  list-style-type: decimal;
}
.prose :where(ul):not(:where([class~="not-prose"] *)) {
  list-style-type: disc;
  padding-left: 1.625em;
}
.prose :where(ol > li):not(:where([class~="not-prose"] *))::marker {
  font-weight: 400;
  color: var(--tw-prose-counters);
}
.prose :where(ul > li):not(:where([class~="not-prose"] *))::marker {
  color: var(--tw-prose-bullets);
}
.prose :where(hr):not(:where([class~="not-prose"] *)) {
  border-color: rgb(212 212 216);
  border-top-width: 1px;
  margin-top: 2em;
  margin-bottom: 2em;
  border-bottom-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  border-style: solid;
}
.prose :where(blockquote):not(:where([class~="not-prose"] *)) {
  font-weight: 500;
  font-style: italic;
  color: var(--tw-prose-quotes);
  border-left-width: 0.25rem;
  border-left-color: #3D9970;
  quotes: "\201C""\201D""\2018""\2019";
  margin-top: 1.6em;
  margin-bottom: 1.6em;
  padding-left: 4em;
  padding: 1rem;
  border-style: solid;
  border-top-width: 0;
  border-bottom-width: 0;
  border-right-width: 0;
  margin-left: 0;
}
.prose :where(h1):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  font-weight: 800;
  font-size: 2.25em;
  margin-top: 0;
  margin-bottom: 0.8888889em;
  line-height: 1.1111111;
}
.prose :where(h1 strong):not(:where([class~="not-prose"] *)) {
  font-weight: 900;
}
.prose :where(h2):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  font-weight: 700;
  font-size: 1.5em;
  margin-top: 2em;
  margin-bottom: 1em;
  line-height: 1.3333333;
}
.prose :where(h2 strong):not(:where([class~="not-prose"] *)) {
  font-weight: 800;
}
.prose :where(h3):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  font-weight: 600;
  font-size: 1.25em;
  margin-top: 1.6em;
  margin-bottom: 0.6em;
  line-height: 1.6;
}
.prose :where(h3 strong):not(:where([class~="not-prose"] *)) {
  font-weight: 700;
}
.prose :where(h4):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  font-weight: 600;
  margin-top: 1.5em;
  margin-bottom: 0.5em;
  line-height: 1.5;
}
.prose :where(h4 strong):not(:where([class~="not-prose"] *)) {
  font-weight: 700;
}
.prose :where(figure > *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
  margin-bottom: 0;
}
.prose :where(figcaption):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-captions);
  font-size: 0.875em;
  line-height: 1.4285714;
  margin-top: 0.8571429em;
}
.prose :where(code):not(:where([class~="not-prose"] *)) {
  color: #ec4899;
  font-weight: 600;
  font-size: 0.875em;
}
.prose :where(code):not(:where([class~="not-prose"] *))::before {
  content: "`";
}
.prose :where(code):not(:where([class~="not-prose"] *))::after {
  content: "`";
}
.prose :where(a code):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-links);
}
.prose :where(pre):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-pre-code);
  background-color: var(--tw-prose-pre-bg);
  overflow-x: auto;
  font-weight: 400;
  font-size: 0.875em;
  line-height: 1.7142857;
  margin-top: 1.7142857em;
  margin-bottom: 1.7142857em;
  border-radius: 0.375rem;
  padding-top: 0.8571429em;
  padding-right: 1.1428571em;
  padding-bottom: 0.8571429em;
  padding-left: 1.1428571em;
}
.prose :where(pre code):not(:where([class~="not-prose"] *)) {
  background-color: transparent;
  border-width: 0;
  border-radius: 0;
  padding: 0;
  font-weight: inherit;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: inherit;
}
.prose :where(pre code):not(:where([class~="not-prose"] *))::before {
  content: none;
}
.prose :where(pre code):not(:where([class~="not-prose"] *))::after {
  content: none;
}
.prose :where(table):not(:where([class~="not-prose"] *)) {
  width: 100%;
  table-layout: auto;
  text-align: left;
  margin-top: 2em;
  margin-bottom: 2em;
  font-size: 0.875em;
  line-height: 1.7142857;
}
.prose :where(thead):not(:where([class~="not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: #eaeaea;
}
.prose :where(thead th):not(:where([class~="not-prose"] *)) {
  color: var(--tw-prose-headings);
  font-weight: 600;
  vertical-align: bottom;
  padding-right: 0.5714286em;
  padding-bottom: 0.5714286em;
  padding-left: 0.5714286em;
}
.prose :where(tbody tr):not(:where([class~="not-prose"] *)) {
  border-bottom-width: 1px;
  border-bottom-color: var(--tw-prose-td-borders);
}
.prose :where(tbody tr:last-child):not(:where([class~="not-prose"] *)) {
  border-bottom-width: 0;
}
.prose :where(tbody td):not(:where([class~="not-prose"] *)) {
  vertical-align: baseline;
  padding-top: 0.5714286em;
  padding-right: 0.5714286em;
  padding-bottom: 0.5714286em;
  padding-left: 0.5714286em;
}
.prose {
  --tw-prose-body: #374151;
  --tw-prose-headings: #111827;
  --tw-prose-lead: #4b5563;
  --tw-prose-links: #111827;
  --tw-prose-bold: #111827;
  --tw-prose-counters: #6b7280;
  --tw-prose-bullets: #d1d5db;
  --tw-prose-hr: #e5e7eb;
  --tw-prose-quotes: #111827;
  --tw-prose-quote-borders: #e5e7eb;
  --tw-prose-captions: #6b7280;
  --tw-prose-code: #111827;
  --tw-prose-pre-code: #e5e7eb;
  --tw-prose-pre-bg: #1f2937;
  --tw-prose-th-borders: #d1d5db;
  --tw-prose-td-borders: #e5e7eb;
  --tw-prose-invert-body: #d1d5db;
  --tw-prose-invert-headings: #fff;
  --tw-prose-invert-lead: #9ca3af;
  --tw-prose-invert-links: #fff;
  --tw-prose-invert-bold: #fff;
  --tw-prose-invert-counters: #9ca3af;
  --tw-prose-invert-bullets: #4b5563;
  --tw-prose-invert-hr: #374151;
  --tw-prose-invert-quotes: #f3f4f6;
  --tw-prose-invert-quote-borders: #374151;
  --tw-prose-invert-captions: #9ca3af;
  --tw-prose-invert-code: #fff;
  --tw-prose-invert-pre-code: #d1d5db;
  --tw-prose-invert-pre-bg: rgb(0 0 0 / 50%);
  --tw-prose-invert-th-borders: #4b5563;
  --tw-prose-invert-td-borders: #374151;
  font-size: 1rem;
  line-height: 1.75;
}
.prose :where(p):not(:where([class~="not-prose"] *)) {
  margin-top: 1.25em;
  margin-bottom: 1.25em;
}
.prose :where(img):not(:where([class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
  max-width: 100%;
  height: auto;
}
.prose :where(video):not(:where([class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}
.prose :where(figure):not(:where([class~="not-prose"] *)) {
  margin-top: 2em;
  margin-bottom: 2em;
}
.prose :where(h2 code):not(:where([class~="not-prose"] *)) {
  font-size: 0.875em;
}
.prose :where(h3 code):not(:where([class~="not-prose"] *)) {
  font-size: 0.9em;
}
.prose :where(li):not(:where([class~="not-prose"] *)) {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.prose :where(ol > li):not(:where([class~="not-prose"] *)) {
  padding-left: 0.375em;
}
.prose :where(ul > li):not(:where([class~="not-prose"] *)) {
  padding-left: 0.375em;
}
.prose > :where(ul > li p):not(:where([class~="not-prose"] *)) {
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}
.prose > :where(ul > li > *:first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.25em;
}
.prose > :where(ul > li > *:last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}
.prose > :where(ol > li > *:first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 1.25em;
}
.prose > :where(ol > li > *:last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 1.25em;
}
.prose :where(ul ul, ul ol, ol ul, ol ol):not(:where([class~="not-prose"] *)) {
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}
.prose :where(hr + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}
.prose :where(h2 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}
.prose :where(h3 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}
.prose :where(h4 + *):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}
.prose :where(thead th:first-child):not(:where([class~="not-prose"] *)) {
  padding-left: 0;
}
.prose :where(thead th:last-child):not(:where([class~="not-prose"] *)) {
  padding-right: 0;
}
.prose :where(tbody td:first-child):not(:where([class~="not-prose"] *)) {
  padding-left: 0;
}
.prose :where(tbody td:last-child):not(:where([class~="not-prose"] *)) {
  padding-right: 0;
}
.prose > :where(:first-child):not(:where([class~="not-prose"] *)) {
  margin-top: 0;
}
.prose > :where(:last-child):not(:where([class~="not-prose"] *)) {
  margin-bottom: 0;
}
.prose :where(h2,h3,h4):not(:where([class~="not-prose"] *)) {
  scroll-margin-top: 8rem;
}
.prose :where(blockquote p:first-of-type):not(:where([class~="not-prose"] *)) {
  margin-top: 0px;
}
.prose :where(blockquote p:last-of-type):not(:where([class~="not-prose"] *)) {
  margin-bottom: 0px;
}
.visible {
  visibility: visible;
}
.static {
  position: static;
}
.fixed {
  position: fixed;
}
.absolute {
  position: absolute;
}
.relative {
  position: relative;
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
}
.left-0 {
  left: 0px;
}
.top-1\/4 {
  top: 25%;
}
.my-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}
.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.mb-16 {
  margin-bottom: 4rem;
}
.mb-8 {
  margin-bottom: 2rem;
}
.mb-4 {
  margin-bottom: 1rem;
}
.mt-6 {
  margin-top: 1.5rem;
}
.ml-4 {
  margin-left: 1rem;
}
.mr-4 {
  margin-right: 1rem;
}
.ml-auto {
  margin-left: auto;
}
.block {
  display: block;
}
.inline-block {
  display: inline-block;
}
.flex {
  display: flex;
}
.hidden {
  display: none;
}
.h-\[450px\] {
  height: 450px;
}
.h-\[48px\] {
  height: 48px;
}
.h-\[1em\] {
  height: 1em;
}
.w-full {
  width: 100%;
}
.w-\[48px\] {
  width: 48px;
}
.w-\[1em\] {
  width: 1em;
}
.w-4 {
  width: 1rem;
}
.max-w-none {
  max-width: none;
}
.flex-1 {
  flex: 1 1 0%;
}
.rotate-180 {
  --tw-rotate: 180deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.cursor-pointer {
  cursor: pointer;
}
.resize {
  resize: both;
}
.flex-col {
  flex-direction: column;
}
.items-start {
  align-items: flex-start;
}
.items-center {
  align-items: center;
}
.justify-center {
  justify-content: center;
}
.space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}
.scroll-smooth {
  scroll-behavior: smooth;
}
.rounded-lg {
  border-radius: 0.5rem;
}
.rounded-sm {
  border-radius: 0.125rem;
}
.rounded-r-sm {
  border-top-right-radius: 0.125rem;
  border-bottom-right-radius: 0.125rem;
}
.border {
  border-width: 1px;
}
.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity));
}
.bg-sky-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(56 189 248 / var(--tw-bg-opacity));
}
.bg-\[\#ff4500\] {
  --tw-bg-opacity: 1;
  background-color: rgb(255 69 0 / var(--tw-bg-opacity));
}
.bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(234 234 234 / var(--tw-bg-opacity));
}
.fill-white {
  fill: #fff;
}
.p-4 {
  padding: 1rem;
}
.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.pb-6 {
  padding-bottom: 1.5rem;
}
.text-left {
  text-align: left;
}
.text-\[20px\] {
  font-size: 20px;
}
.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}
.font-medium {
  font-weight: 500;
}
.uppercase {
  text-transform: uppercase;
}
.leading-5 {
  line-height: 1.25rem;
}
.text-primary-main {
  --tw-text-opacity: 1;
  color: rgb(var(--color-primary-main) / var(--tw-text-opacity));
}
.underline {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}
.no-underline {
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
}
.accent-primary-main {
  accent-color: rgb(var(--color-primary-main) / 1);
}
.outline {
  outline-style: solid;
}
.ring-blue-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(191 219 254 / var(--tw-ring-opacity));
}
.ring-sky-200 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(186 230 253 / var(--tw-ring-opacity));
}
.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.transition {
  transition-property: color, background-color, border-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.transition-transform {
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

#printy6-blog {
  --color-primary-main: 4 120 87;
  --color-primary-dark: 17 94 89;
}

.prose .anchor {
  visibility: hidden;
  position: absolute;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;

  margin-left: -1em;
  padding-right: 0.5em;
  width: 80%;
  max-width: 700px;
  cursor: pointer;
}

.anchor:hover {
  visibility: visible;
}

.prose a {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.prose .anchor:after {
  --tw-text-opacity: 1;
  color: rgb(153 153 153 / var(--tw-text-opacity));
}

.dark .prose .anchor:after {
  --tw-text-opacity: 1;
  color: rgb(51 51 51 / var(--tw-text-opacity));
}

.prose .anchor:after {
  content: '#';
}

.prose *:hover > .anchor {
  visibility: visible;
}

.prose pre {
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(234 234 234 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.dark .prose pre {
  --tw-border-opacity: 1;
  border-color: rgb(51 51 51 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(17 17 17 / var(--tw-bg-opacity));
}

.prose code {
  border-radius: 0.5rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(250 250 250 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity));
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
  --tw-text-opacity: 1;
  color: rgb(34 34 34 / var(--tw-text-opacity));
}

.dark .prose code {
  --tw-border-opacity: 1;
  border-color: rgb(34 34 34 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(17 17 17 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(234 234 234 / var(--tw-text-opacity));
}

.prose pre code {
  padding: 0px;
  --tw-text-opacity: 1;
  color: rgb(34 34 34 / var(--tw-text-opacity));
}

.dark .prose pre code {
  --tw-text-opacity: 1;
  color: rgb(234 234 234 / var(--tw-text-opacity));
}

.prose pre code {
  border: initial;
}

.prose img {
  /* Don't apply styles to next/image */
  margin: 0px;
}

.prose figcaption {
  /* Don't apply styles to next/image */
  margin-top: 0.5rem;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  --tw-text-opacity: 1;
  color: rgb(102 102 102 / var(--tw-text-opacity));
}

.prose figcaption b {
  /* Don't apply styles to next/image */
  font-weight: 600;
}

.prose figcaption a {
  /* Don't apply styles to next/image */
  cursor: pointer;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
}

.prose figcaption a:hover {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

.hover\:text-\[26px\]:hover {
  font-size: 26px;
}

.hover\:accent-primary-dark:hover {
  accent-color: rgb(var(--color-primary-dark) / 1);
}

.hover\:ring-4:hover {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.dark .dark\:prose-dark {
  color: #eaeaea;
}

.dark .dark\:prose-dark :where(a):not(:where([class~="not-prose"] *)) {
  color: #60a5fa;
}

.dark .dark\:prose-dark :where(a):not(:where([class~="not-prose"] *)):hover {
  color: #2563eb;
}

.dark .dark\:prose-dark :where(a):not(:where([class~="not-prose"] *)) code {
  color: #60a5fa;
}

.dark .dark\:prose-dark :where(blockquote):not(:where([class~="not-prose"] *)) {
  border-left-color: #333333;
  color: #999999;
}

.dark .dark\:prose-dark :where(h2,h3,h4):not(:where([class~="not-prose"] *)) {
  color: #fafafa;
  scroll-margin-top: 8rem;
}

.dark .dark\:prose-dark :where(hr):not(:where([class~="not-prose"] *)) {
  border-color: #333333;
}

.dark .dark\:prose-dark :where(ol):not(:where([class~="not-prose"] *)) li:before {
  color: #666666;
}

.dark .dark\:prose-dark :where(ul):not(:where([class~="not-prose"] *)) li:before {
  background-color: #666666;
}

.dark .dark\:prose-dark :where(strong):not(:where([class~="not-prose"] *)) {
  color: #fafafa;
}

.dark .dark\:prose-dark :where(thead):not(:where([class~="not-prose"] *)) th {
  color: #fafafa;
}

.dark .dark\:prose-dark :where(thead):not(:where([class~="not-prose"] *)) {
  border-bottom-color: #444444;
}

.dark .dark\:prose-dark :where(tbody):not(:where([class~="not-prose"] *)) tr {
  border-bottom-color: #333333;
}

.dark .dark\:bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(34 34 34 / var(--tw-bg-opacity));
}

